import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useModal, useProductQuery } from "_hooks";
import useSyncfonia from "../../useSyncfonia";
import { Button } from "components/atoms";
import Controls from "./Controls";
import Processing from "./Processing";
import Done from "./Done";
import { selectSkuSelected } from "_redux/ui/ui.selector";
import { fetchAllList } from "_redux/product/product.actions";
import { toggleRefresh } from "_redux/ui/ui.actions";
import { sleep } from "_utils/async";
import { chunk as _chunk } from "_utils/array";

const prefix = "syncfoniaUpdateGtinModal";

const UpdateGtinModal = ({
  type = "retail"
}) => {
  const dispatch = useDispatch();
  const { closeModal } = useModal();

  const [current, setCurrent] = useState(0);
  const [data, setData] = useState();
  const [opt1, setOpt1] = useState(false);
  const [opt2, setOpt2] = useState(false);
  const [step, setStep] = useState(1);
  const [total, setTotal] = useState(1);
  const selections = useSelector(selectSkuSelected);

  const { getFromQuery } = useProductQuery();
  const { updateGtin, updateGtinProvider } = useSyncfonia();
  const { t } = useTranslation();

  const sendRequest = async () => {
    let selection = Object.keys(selections.selected);
    let optFunction = updateGtin;

    if (type === " provider") optFunction = updateGtinProvider;

    if (selections.all) {
      let { w } = await getFromQuery();
      let response = await dispatch(fetchAllList({ w: w || {} }));
      selection = response?.data?.map((p) => p._id);
    }

    const chunk = _chunk(selection, 5);
    let success = 0;
    
    setTotal(selection?.length)
    setStep(2);

    let _current = 0;
    for (let i = 0; i < chunk.length; i++) {
      _current += chunk[i].length;
      let res = await sleep(200).then(() => {
        return optFunction({
          gtins: chunk[i],
          opts: {
            getAssets: opt1,
            getInfo: opt2
          }
        }, { feedback: { error: false, success: false }});
      });
      setCurrent(_current);
      success = success + (res?.data?.[0]?.success ?? 0)
    }

    setData({ success, error: selection?.length - success });
    setStep(3)
  };

  const element = () => {
    switch (step) {
      case 1:
        return <Controls 
        opt1={opt1}
        opt2={opt2}
        prefix={prefix}
        setOpt1={setOpt1}
        setOpt2={setOpt2}
        />
      case 2:
        return <Processing
          prefix={prefix}
          itemCurrent={current}
          itemTotal={total}
          current={(current / total) * 5}
          total={5}
        />
      case 3:
        return <Done
          data={data}
          prefix={prefix}
        />
      default:
        return null;
    }
  };

  return (
    <div className={prefix}>
      {element()}

      <div className={`${prefix}__buttons`}>
        
        <Button
          disabled={step === 3}
          content={t("common:form.cancel")}
          onClick={closeModal}
          style={{ opacity: "0"}}
          type="underline"
        />
        
        
        {step === 1 &&
          <Button
            content={t("common:words.continue")}
            disabled={(!selections?.all && !Object.keys(selections?.selected).length) || (!opt1 && !opt2) }
            sm
            type="purple"
            onClick={() => sendRequest()}
          />
        }

        {step === 3 &&
          <Button
            content={t("common:words.close")}
            sm
            type="purple"
            onClick={() => {
              closeModal();
              dispatch(toggleRefresh("products"));
            }}
          />
        }

      </div>
    </div>
  );
};

export default UpdateGtinModal;
