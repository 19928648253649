import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";

import { Button, NubeskIcons, Title } from "components/atoms";
import { closeModal } from "_redux/ui/ui.actions";
import { selectModal } from "_redux/ui/ui.selector";
import {
  ApprovePhotoModal,
  AddAdaptationModal,
  AddLayerModal,
  AdaptationsBulkModal,
  AttributeFormModal,
  BackRemovalModal,
  DownloadXlsModal,
  ConfirmModal,
  EditFieldBulkModal,
  FaqModal,
  GenerateAdaptationModal,
  GenerateLinkModal,
  GenerateLayerImagesModal,
  GlobalAttributeModal,
  NewAdaptationModal,
  NewCatalogModal,
  NewChannelModal,
  NewPropertyModal,
  AddToTaskModal,
  RemoveBgBulkModal,
  RemoveFromCatalogModal,
  RemoveFromTaskModal,
  RemoveProductModal,
  SharedModal,
  TaskEditionModal,
  UploadBulkImages,
  UploadFileModal,
  UploadImagesModal,
  UploadMediaLibModal,
  UserFormModal
} from "components/organism/Modals";

import { UpdateCatalogModal, UpdateGtinModal } from "module/Syncfonia/components";
import FlexComponent from "../FlexComponent";
import { RemovalQuotaModal } from "components/organism/Modals/index";

const modalManager = {
  adaptations: AddAdaptationModal,
  adaptationsBulk: AdaptationsBulkModal,
  addLayer: AddLayerModal,
  addToTask: AddToTaskModal,
  approveBulkPhoto: ApprovePhotoModal,
  attributeForm: AttributeFormModal,
  backRemoval: BackRemovalModal,
  confirm: ConfirmModal,
  downloadXls: DownloadXlsModal,
  editFieldBulk: EditFieldBulkModal,
  editTask: TaskEditionModal,
  faq: FaqModal,
  generateAdaptation: GenerateAdaptationModal,
  generateLayerImages: GenerateLayerImagesModal,
  generateLink: GenerateLinkModal,
  globalAttribute: GlobalAttributeModal,
  newAdaptation: NewAdaptationModal,
  newCatalog: NewCatalogModal,
  newChannel: NewChannelModal,
  newProperty: NewPropertyModal,
  removalQuota: RemovalQuotaModal,
  removeBgBulk: RemoveBgBulkModal,
  removeFromCatalog: RemoveFromCatalogModal,
  removeFromTask: RemoveFromTaskModal,
  removeProduct: RemoveProductModal,
  share: SharedModal,
  "syncfonia:updateCatalog": UpdateCatalogModal,
  "syncfonia:updateGtin": UpdateGtinModal,
  upload: UploadFileModal,
  uploadImages: UploadImagesModal,
  uploadBulkImages: UploadBulkImages,
  uploadMediaLib: UploadMediaLibModal,
  userForm: UserFormModal
};

const prefix = "t-modal";

const Modal = () => {
  const dispatch = useDispatch();
  const {
    closeBackdrop,
    typeModal,
    isClosable = true,
    isOpen,
    modalProps = {},
    header
  } = useSelector(selectModal);

  if (!isOpen) return false;
  
  const classForComponent = classNames(prefix, {
    [`${prefix}--${typeModal}`]: typeModal,
  });

  let renderedModal;
  if (typeof modalManager[typeModal] !== "undefined") {
    const ModalComponent = modalManager[typeModal];
    renderedModal = <ModalComponent {...modalProps} />;
  }

  return (
    <div className={classForComponent}>
      <div
        style={typeModal.includes("upload") || typeModal.includes("Bulk") ? { pointerEvents: "none" } : null}
        className={`${prefix}__mask`}
        onClick={() => {
          if (closeBackdrop === false) return;
          dispatch(closeModal());
        }}
      ></div>
      <div className={`${prefix}__content`}>
        <div className={`${prefix}__content-header`}>
          <FlexComponent gap={10}>
            {header?.icon && <NubeskIcons icon={header.icon?.type} size={header.icon?.size} />}
            {header?.text && <Title content={header?.text} secondary />}
          </FlexComponent>
          {header?.rightItem && <div className={`${prefix}__content-header-right`}>
            <FlexComponent>
              <span>{header?.rightItem}</span>
            </FlexComponent>
          </div>}
          {isClosable &&
            <Button
              className={`${prefix}__close`}
              content={<NubeskIcons icon="close" />}
              type="color"
              onClick={() => dispatch(closeModal())}
            />
          }
        </div>
        {renderedModal}
      </div>
    </div>
  );
};

export default Modal;
